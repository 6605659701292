// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { NewLandingPage } from "blocks/views/LandingPage";
import { Layouts } from "blocks/views/Layouts";
import DataSuite from "blocks/views/LandingPage/features/DataSuite";
const LandingPage = () => {
  return (
    <Layouts
      data-testid="LandingPage"
      viewContext="home"
      pageTitle="Welcome to Trryst"
      showPathCrumbs={false}
    >
      {/* <NewLandingPage /> */}

      <DataSuite />
    </Layouts>
  );
};

export default LandingPage;
