import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import {
  HeroPanel,
  ProofPoints,
  FeaturesPanel,
  HighlightsPanel,
  SuiteIllustrationsPanel,
  CTAPanel,
} from "./features";
import { LandingFooter } from "blocks/views/Layouts/LandingPageLayout";
import { WavePanel } from "./foundations";

export default function NewLandingPage() {
  return (
    <Box sx={{ position: "relative", backgroundColor: "white" }}>
      <HeroPanel />
      <WavePanel />
      <ProofPoints />
      <FeaturesPanel />
      <HighlightsPanel />
      <SuiteIllustrationsPanel />
      <CTAPanel />
      <LandingFooter />
    </Box>
  );
}
